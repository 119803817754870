<template>
    <div class="container">
        <div class="headerVideo">
            <videoPlayer class="video-player-box" ref="videoPlayer" :options="playerOptions" :playsinline="true" />
            <div class="headerContent">
                <div v-if="!flag" class="title">知麦链ZMchain</div>
                <div :class="['serchContainer',flag?'flag':'']">
                    <el-image style="width:16px; height: 16px;flex-shrink: 0;" :src="require('@/assets/image/search.png')" fit="fill"></el-image>
                    <!-- <el-image style="width:16px; height: 16px;flex-shrink: 0;" src="http://124.222.245.15:2022/v2.mp4?t=1" fit="fill"></el-image> -->
                    <el-input class="input" placeholder="按区块高度或交易Hash搜索" v-model="input"></el-input>
                    <el-button class="searchBtn" type="primary" @click="search">搜索</el-button>
                </div>
            </div>
        </div>

        <div class="page">
            <div class="cardList">
                <div class="card">
                    <div class="iconContainer">
                        <div class="round">
                            <img src="../assets/image/icon1.png" alt="">
                        </div>
                    </div>
                    <div class="vContainer">
                        <span class="v">{{listNodes.length}}</span>
                        <span class="unit">个</span>
                    </div>
                    <div class="t">节点数</div>
                </div>
                <div class="card">
                    <div class="iconContainer">
                        <div class="round">
                            <img src="../assets/image/icon2.png" alt="">
                        </div>
                    </div>
                    <div class="vContainer">
                        <span class="v">300</span>
                        <span class="unit">家</span>
                    </div>
                    <div class="t">接入企业</div>
                </div>
                <div class="card">
                    <div class="iconContainer">
                        <div class="round">
                            <img src="../assets/image/icon3.png" alt="">
                        </div>
                    </div>
                    <div class="vContainer">
                        <span class="v">{{transactionData.total}}</span>
                        <span class="unit">条</span>
                    </div>
                    <div class="t">上链数据</div>
                </div>
                <div class="card">
                    <div class="iconContainer">
                        <div class="round">
                            <img src="../assets/image/icon4.png" alt="">
                        </div>
                    </div>
                    <div class="vContainer">
                        <span class="v">{{blockNumber}}</span>
                    </div>
                    <div class="t">区块高度</div>
                </div>
                <div class="card">
                    <div class="iconContainer">
                        <div class="round">
                            <img src="../assets/image/icon5.png" alt="">
                        </div>
                    </div>
                    <div class="vContainer">
                        <span class="v">{{runDateTime()}}</span>
                        <span class="unit">天</span>
                    </div>
                    <div class="t">运行天数</div>
                </div>
            </div>
            <div class="newBlockCard">
                <div class="t">最新区块</div>
                <div class="table">
                    <div class="row tableHeader">
                        <div class="cell">高度</div>
                        <div class="cell">创建时间</div>
                        <div class="cell">打包节点</div>
                        <div class="cell">记录数量</div>
                    </div>
                    <el-scrollbar class="mainContainer">
                        <div class="row animate__animated animate__fadeInDown" v-for="item in data" :key="item._id">
                            <div class="cell" @click="queryInfo(item)">#{{item.number}}</div>
                            <div class="cell">{{toStrDateTime(item.createTimestamp)}}</div>
                            <div class="cell">{{item.sealerData.nodeName}}</div>
                            <div class="cell">
                                {{item.transactionCount}}
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    import 'video.js/dist/video-js.css'
    import { videoPlayer } from 'vue-video-player'
    import axiosCB from '@/axios/indexCB'

    export default {
        name: "home",
        components: {
            videoPlayer
        },
        data() {
            return {
                flag: true, //艳峰  后期优化
                flagnum: 1,
                timer: null,
                input: "",
                playerOptions: {
                    muted: true, // 默认情况下将会消除任何音频。
                    language: 'zh-CN',
                    autoplay: true, //如果true,浏览器准备好时开始回放。
                    loop: true, // 导致视频一结束就重新开始。
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    // playbackRates: [0.5, 1.0, 1.5, 2.0],
                    sources: [{
                        type: "video/mp4",
                        src: require("@/assets/image/headerVideo.mp4?t=1")
                    }],
                    controlBarShow: false,
                    // poster: require("@/assets/image/partner1.png"),

                },
                listNodes: [], //节点列表
                data: [], //最新区块集合
                blockNumber: 0, //区块高度
                transactionData: { //交易信息
                    list: [],
                    total: 0
                }
            }
        },
        watch: {
            data: {
                handler: function(nv, ov) {
                    this.blockNumber = nv[0].number
                },
                deep: true
            }
        },
        beforeCreate(){
            window.addEventListener(
                "message",
                (event) => {
                    console.log('event', event.data)
                    // 根据接收的消息隐藏对应元素
                    if (event.data.footer) {
                        this.flag = true
                        this.flagnum = 0
                    }
                },
                false
            );
            
        },
        async created() {

            this.start()
            if (this.timer) clearInterval(this.timer)
            this.timer = setInterval(() => {
                this.start()
            }, 60000)
        },
        destroyed() {
            clearInterval(this.timer)
        },
        
        mounted() {
            setTimeout(()=>{
                console.log(this.flagnum)
                if(this.flagnum == 1) {
                    this.flag = false
                }
            }, 500)
        },
        methods: {
            start() {
                axiosCB.request({
                    url: `network/${this.$store.state.networkName}/blocks?page=1&size=6`,
                    method: "GET"
                }).then(async res => {
                    let data = res.data.list || []
                    let reqList = []
                    for (let i = 0; i < data.length; i++) {
                        const item = data[i];
                        reqList.push(
                            axiosCB.request({
                                url: `/network/${this.$store.state.networkName}/blocks/sealer/${item.blockHash}`,
                                method: "GET"
                            })
                        )
                    }
                    let resList = await Promise.all(reqList)
                    for (let i = 0; i < data.length; i++) {
                        const item = data[i];
                        item.sealerData = resList[i].data || {}
                    }
                    this.data = data
                })
                this.queryListNodes()
                this.queryTransaction()
            },
            queryInfo(obj) {
                this.$router.push({
                    name: "searchResult",
                    query: {
                        value: obj.number
                    }
                })
            },
            queryListNodes() { //列出节点列表
                axiosCB.request({
                    url: `/network/listNodes?networkName=${this.$store.state.networkName}`,
                    method: "GET",
                }).then(res => {
                    this.listNodes = res.data
                })
            },
            runDateTime() {
                let eventdate = moment("2022-08-10");
                let todaysdate = moment();
                return todaysdate.diff(eventdate, 'days') || 1
            },
            toStrDateTime(v) {
                if (!v) return ''
                return moment(v).add(0, 'h').format("YYYY-MM-DD HH:mm:ss")
            },
            search() {
                let value = this.input
                if (value !== '0') {
                    if (!value) {
                        this.$message.warning("请输入查询内容")
                        return
                    }
                }

                this.$router.push({
                    name: "searchResult",
                    query: {
                        value
                    }
                })
            },
            queryNewBlock() { //查询最新区块
                axiosCB.request({
                    url: `network/${this.$store.state.networkName}/blocks/-1`,
                    method: "GET"
                }).then(res => {
                    if (this.data.find(item => item._id === res.data._id)) return
                    this.data.unshift(res.data)
                })
            },
            queryTransaction() { //查询交易列表 交易总数
                axiosCB.request({
                    url: `/network/${this.$store.state.networkName}/transactions/1/1`,
                    method: "GET"
                }).then(res => {
                    this.transactionData = res.data
                })
            }
        }
    }
</script>
<style lang="less">

</style>
<style scoped lang="less">
    /deep/ .video-js .vjs-big-play-button {
        //视频播放按钮
        display: none;
    }

    .container {
        position: relative;
        width: 100%;
        background-color: #FFFEFF;
        overflow: hidden;
    }

    .headerVideo {
        position: relative;
        height: 640px;
        width: 100%;
        overflow: hidden;
        background-color: rgba(30, 205, 164, 1);
        background: rgba(30, 205, 164, 1);

        .video-player-box {
            box-sizing: border-box;
            width: 100%;
            height: 640px;
        }

        /deep/ video {
            object-fit: cover;
            height: 640px;
            background: rgba(30, 205, 164, 1);
            background-color: rgba(30, 205, 164, 1);
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }


        /deep/ .vjs-control-bar {
            display: none;
        }


        .headerContent {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }


        .title {
            text-align: center;
            margin-top: 342px;
            font-size: 58px;
            font-weight: 600;
            color: #FFFFFF;
            text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1600);
        }

        .serchContainer {
            margin: 0 auto;
            margin-top: 35px;
            width: 700px;
            height: 50px;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px 1px rgba(7, 50, 43, 0.1000);
            border-radius: 40px 40px 40px 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            padding-left: 24px;
            box-sizing: border-box;

            &.flag {
                margin-top: 280px;
            }

            .input {
                flex: auto;
                margin: 0 16px;
                font-size: 14px;
                font-weight: 400;

                /deep/ .el-input__inner {
                    border-color: rgba(0, 0, 0, 0) !important;
                }
            }

            .searchBtn {
                flex-shrink: 0;
                height: 40px;
                line-height: 40px;
                padding: 0;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                background: #1ECDA4;
                width: 88px;
                border-radius: 40px 40px 40px 40px;
                border-color: unset;
            }
        }


    }


    .page {
        width: 1200px;
    }




    .cardList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 94px;

        .card {
            flex: 1;
            margin-left: 25px;
            position: relative;
            background: #FFFFFF;
            box-shadow: inset 0px 3px 8px 1px rgba(7, 50, 43, 0.1000);
            border-radius: 10px 10px 10px 10px;
            border: 1px solid rgba(82, 187, 163, 0.1000);
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 138px;
            position: relative;

            &:first-of-type {
                margin-left: 0;
            }

            .iconContainer {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;

                .round {
                    width: 60px;
                    height: 60px;
                    background: #FFFFFF;
                    box-shadow: 0px 3px 6px 1px rgba(7, 21, 50, 0.1000);
                    border-radius: 40px 40px 40px 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    top: -30px;

                    &::after {
                        content: "";
                        position: absolute;
                        z-index: 999;
                        left: 5px;
                        top: 5px;
                        width: 50px;
                        height: 50px;
                        background: #5274BB;
                        border-radius: 40px 40px 40px 40px;
                        opacity: 0.05;
                    }
                }

            }

            .vContainer {
                margin-top: 50px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                position: relative;
                left: 15px;

                .v {
                    font-size: 28px;
                    font-weight: 600;
                    color: #333333;
                    margin-right: 16px;
                }
            }

            .t {
                margin-top: 12px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
            }

        }
    }

    .newBlockCard {
        margin-top: 56px;
        margin-bottom: 64px;

        .t {
            font-size: 20px;
            font-weight: 600;
            color: #333333;
            text-align: left;
        }

        .table {
            margin-top: 16px;
            max-height: 350px;
            min-height: 205px;
            font-size: 14px;
            font-weight: 600;
            color: #333333;
            display: flex;
            flex-direction: column;

            .cell {
                flex: 1;
                padding: 15px 24px;
                box-sizing: border-box;
            }

            .row {
                display: flex;
                width: 100%;
            }

            .tableHeader {
                background-color: rgba(240, 243, 242, 0.6);
                font-weight: 600;
            }

            .mainContainer {
                flex: auto;
                box-sizing: border-box;
                font-weight: 400;

                /deep/ .is-horizontal {
                    display: none !important;
                }

                .row {
                    .cell {
                        &:nth-child(1) {
                            color: #16BF98;
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    &:nth-child(even) {
                        background-color: rgba(240, 243, 242, 0.3);
                    }
                }
            }
        }
    }
</style>